body {
	overflow:hidden;
	margin:0px;
	background-color: #171717;
}
.hidden {
	visibility:hidden;
}
#bottombar {
	position:fixed;
	background-color:#282828;
	width:100vw;
	height:4.5vh;
	top:95.5vh;
	z-index:100;
}
#brand {
	position:fixed;
	background-color:#00a7c4;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight:600;
	font-size:4vh;
	color:white;
	padding-left:1.5px;
	padding-right:3px;
	width:25vw;
	height:4.5vh;
	top:95.5vh;
	left:0px;
}
#donation-total {
  position:fixed;
	background-color:#55e5ff;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight:600;
	font-size:4vh;
	color:white;
	padding-left:1.5px;
	padding-right:3px;
	width:auto;
	height:4.5vh;
	top:95.5vh;
	left:20vw;
}
#feature {
	position:fixed;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight:600;
	font-size:4vh;
	color:white;
	top:95.5vh;
	right:0px;
}
#barbody {
	position:relative;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight:600;
	font-size:4vh;
  color:white;
  left:50vw;
}
.twitter-timeline {
	background-color: rgba(25,25,25,.95);
}
.module-column-1 {
  position:fixed;
  left:30.5vw;
	height:95.5vh;
	opacity: .95;
}
.module-column-2 {
  position:fixed;
  left:64.5vw;
	height:95.5vh;
	opacity: .95;
}
.module-column-1 span {
  display: flex;
  position: relative;
  width:33vw;
  height:29.6vh;
  margin:1vw;
  vertical-align: top;
  overflow: hidden;
}
.module-column-2 span {
  display: flex;
  position: relative;
  width:33vw;
  height:29.6vh;
  margin:1vw;
  vertical-align: top;
  overflow: hidden;
}
.module-container {
  position:relative;
  width:100%;
  height:100%;
  overflow: hidden;
  color:white;
  font-size: 3.5vh;
}
.module-container ul {
  margin-top:0px;
  margin-bottom:0px;
}
.module-header {
  position: relative;
  height: 6vh;
  width: 100%;
  background-color: rgba(0,0,0,.25);
}
.module-header div, h1, MaterialIcon {
  display: inline-block;
  vertical-align: top; 
  font-size:5vh;
  line-height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color:white;
}
.tony {
	position:fixed;
	z-index: -1000;
	width:100vw;
	height:100vh;
}
.subtext {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:4.5vh;
	color:white;
	font-weight: 500;
}
.subtext-l {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:5vh;
	color:white;
	font-weight: 500;
}
.subtext-xl {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:5.5vh;
	color:white;
	font-weight: 500;
}
.color-change {
	-webkit-animation: color-change-2x 2000ms linear infinite alternate both;
	animation: color-change-2x 2000ms linear infinite alternate both;
}
@-webkit-keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}
@keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}
.max {
	width: 100%;
	height: 100vh;
}
